import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

const apiKey = '5dbe0748b2abf41b336e7bf8e93072dd'

console.log('Release:', process.env.NODE_ENV)

if (location.hostname !== 'localhost') {
    Bugsnag.start({
        apiKey,
        releaseStage: process.env.NODE_ENV,
        plugins: [new BugsnagPluginReact()]
    })

    BugsnagPerformance.start({ apiKey })
}

import Bugsnag from '@bugsnag/js'

type ErrorWithMessage = {
    message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return typeof error === 'object' && error !== null && 'message' in error && typeof (error as Record<string, unknown>).message === 'string'
}

function toErrorWithMessage(error: unknown): ErrorWithMessage {
    if (isErrorWithMessage(error)) {
        return error
    }

    try {
        return new Error(JSON.stringify(error))
    } catch {
        return new Error(String(error))
    }
}

/**
 * @returns The error.message from an unknown error caught in a catch block.
 *
 * Falls back to stringify and toString if the object doesn't have an error.
 */
function getErrorMessage(error: unknown): string {
    return toErrorWithMessage(error).message
}

export function reportError(error: string | unknown) {
    const message: string = typeof error === 'string' ? error : getErrorMessage(error)
    Bugsnag.notify(new Error(message))
}

import { TimeUnit } from '../utils/constants'
import { AdStatus } from './AdPlatforms'
import { Group } from './AudienceBuilder'
import { SegmentPrice } from './Pricing'
import { SegmentStatus } from './SegmentStatus'
import { SegmentType } from './SegmentType'

export interface BaseSegmentDto {
    name: string
    size: number
    singleSite: number
    type: SegmentType
    durationDays: number
    status: SegmentStatus
    lookalikeThreshold?: number
    definition?: {
        nodeList?: Node[]
        operator?: string
    }
}

export interface AdminSegmentDto {
    equativ: {
        id: string
        active: boolean
        name: string
    }
    segment: {
        id: number
        externalId: number
        publicName: string
        name: string
        size: number
        status: SegmentStatus
        companyId: number
        companyName: string
        activatedAt: string
        createdAt: string
        durationDays: number
        type: SegmentType
    }
    xandr: {
        id: number
        name: string
        shared: [
            {
                buyerId: string
                companyId: number
                companyName: string
            }
        ]
        ttl: string
    }
}

export interface BaseAdminSegmentDto {
    id: number
    externalId: number
    publicName: string
    name: string
    status: SegmentStatus
    companyId: number
    companyName: string
}

export interface BaseAdminXandrDto {
    id: number
    name: string
    shared: [
        {
            buyerId: string
            companyId: number
            companyName: string
        }
    ]
    ttl: string
}

export interface SegmentDto {
    externalId?: string
    shortName?: string
    isShared?: boolean
    name: string
    companyId?: number
    id?: number
    lookalikeThreshold?: number
    interestScore?: number
    type: SegmentType
    publicName?: string
    cpm?: any
    createdAt?: string
    activatedAt?: string
    retargeting?: any
    status: SegmentStatus
    size: number
    lookalikeSize?: number
    durationDays: number
    campaignId?: number
    reportUrl?: string
    definition?: Group
    equativPrice?: string
    xandrPrice?: string
    criteoPrice?: string
    gamPrice?: string
    pubmaticPrice?: string
    targeting?: TargetingDto
    lookalikeCats?: string[]
    config?: {
        gam?: {
            ppsEnabled?: boolean
        }
        public?: boolean
    }
    segmentData?: {
        name: string
    }
}

export interface Segment {
    prices: SegmentPrice[]
    segment: SegmentDto
}

export enum SegmentKeys {
    shortName = 'shortName',
    isShared = 'isShared',
    name = 'name',
    companyId = 'companyId',
    id = 'id',
    lookalikeThreshold = 'lookalikeThreshold',
    interestScore = 'interestScore',
    type = 'type',
    publicName = 'publicName',
    cpm = 'cpm',
    createdAt = 'createdAt',
    activatedAt = 'activatedAt',
    retargeting = 'retargeting',
    status = 'status',
    size = 'size',
    lookalikeSize = 'lookalikeSize',
    durationDays = 'durationDays',
    campaignId = 'campaignId',
    reportUrl = 'reportUrl',
    definition = 'definition',
    equativPrice = 'equativPrice',
    xandrPrice = 'xandrPrice',
    criteoPrice = 'criteoPrice',
    gamPrice = 'gamPrice',
    pubmaticPrice = 'pubmaticPrice',
    targeting = 'targeting',
    lookalikeCats = 'lookalikeCats'
}

export interface TargetingDto {
    category: string[]
    city: string[]
    country: string[]
    deviceBrowser: string[]
    deviceOs: string[]
    deviceType: string[]
    keyword: string[]
    language: string[]
    sizeRecency: string[]
    topicIds: number[]
    gender: string[]
    networth: string[]
    lookalikeCats: string[]
}

export interface SegmentReach {
    reach: {
        date: string
        size?: number
    }[]
    reachTotal?: {
        date?: string
        size?: number
    }[]
    segmentID: number
}

export interface RetargetingPages {
    id: number
    address?: { label: string; value: string }
    condition?: { label: string; value: string }
    value?: string
}

export interface RetargetingProperties {
    id: number
    event?: { label: string; value: string }
    condition?: { label: string; value: string }
    value?: string
}

export interface Retargeting {
    domains: string[]
    definition?: RetargetingDefinition
}
export interface RetargetingDefinition {
    rollingPeriod?: { label: string; value: number }
    pages?: RetargetingPages[]
    properties?: RetargetingProperties[]
    expiration?: { label: string; value: number }
}
export interface DatePeriod {
    from: Date
    to: Date
}

export interface Device {
    mobile: boolean
    os: string
    browser: string
}

export interface SegmentIds {
    segmentId: number
    buyerId: number
}

export interface BasePubmaticSegment {
    details: {
        accountId: number
        targetValue: string
        price: number
    }
    status: AdStatus
}

export interface PubmaticSegment {
    publicName: string
    accountId: number
    publisherId: number
    targetValue: string
    price: number
}

export interface FrequencyCap {
    seconds: number
    views: number
}

export enum SegmentActivationStatus {
    Sent = 'sent',
    NotSent = 'not_sent'
}

export enum RetargetingCondition {
    id = 'id',
    css = 'css',
    campaignId = 'campaignId',
    lineItemId = 'lineItemId',
    creativeId = 'creativeId'
}

export enum RetargetingEvent {
    dataLayer = 'dataLayer',
    click = 'click',
    slotClicked = 'slotClicked',
    slotViewed = 'slotViewed'
}

export { SegmentType }

export type ImpressionTrackerFrequency = {
    seconds: number
    views: number
}

export type ImpressionTrackerFrequencyWithUnit = {
    seconds?: number
    views?: number
    timeUnit: TimeUnit
}

export type ImpressionTracker = {
    frequencies: ImpressionTrackerFrequency[]
    segmentId: number
    uuid: string
}

export type Topic = { category: string; id: number; name: string; full: string }

export type AggregatedTopic = { name: string; category: string; full: string; ids: number[]; id: string }

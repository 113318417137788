import { SegmentStatus } from '../types'
import { AdminService } from '../services/adminService'
import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx'
import { RootStore } from './rootStore'
import { AdminSegmentDto, Segment, SegmentReach } from '../types/Segment'
import { ToastType } from '../types/ToastData'
import { companyService } from '../services/companyService'
import { CompanyData } from '../types/Company'

export class AdminStore {
    private adminService: AdminService
    private rootStore: RootStore

    error: string = null
    segments: AdminSegmentDto[]
    companySegments: Segment[]
    adminCompany: CompanyData
    currentSegment: Segment
    segmentsReach: SegmentReach[]

    constructor(rootStore: RootStore, adminService: AdminService) {
        this.adminService = adminService
        this.rootStore = rootStore
        this.segments = []
        this.companySegments = []
        this.currentSegment = null
        this.adminCompany = null
        this.segmentsReach = []

        makeAutoObservable(this)

        onBecomeObserved(this, 'segmentsReach', async () => {
            if (this.segmentsReach.length > 0) return
            const res = this.rootStore.authStore.isAdmin ? await this.adminService.getReachAdmin() : []
            this.rootStore.appStore.pendingRequests++
            runInAction(() => {
                this.segmentsReach = res
            })
            this.rootStore.appStore.pendingRequests--
        })
    }

    async getAllSegments() {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.adminService.getAllSegmentsAdmin()
            runInAction(() => {
                this.segments = res
            })
        } catch (error) {
            this.error = error?.message || 'Failed to get segments'
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get segments'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanySegments(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.adminService.getCompanySegmentsAdmin(id)
            runInAction(() => {
                this.companySegments = res
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company segments'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getSegment(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.adminService.getSegmentAdmin(id)
            runInAction(() => {
                this.currentSegment = res
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get the segment'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async publish(segment: any) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.adminService.publishSegmentAdmin(segment.id, segment.companyId)
            const index = this.segments.findIndex((x) => x.segment.id === segment.id)
            if (index >= 0) {
                runInAction(() => {
                    this.segments[index].segment.status = SegmentStatus.Published
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to publish the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async archive(segment: any) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.adminService.archiveSegmentAdmin(segment.id, segment.companyId)
            const index = this.segments.findIndex((x) => x.segment.id === segment.id)
            if (index >= 0) {
                runInAction(() => {
                    this.segments[index].segment.status = SegmentStatus.Archived
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to archive the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async save(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const index = this.segments.findIndex((x) => x.segment.id === id)

            const resp = await this.adminService.getSegmentAdmin(id)
            await this.adminService.updateSegmentAdmin(resp.segment)
            if (index >= 0) {
                runInAction(() => {
                    this.segments[index].segment.status = SegmentStatus.Draft
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to save the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async delete(id: number, companyId: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.adminService.deleteSegment(id, companyId)
            const index = this.segments.findIndex((x) => x.segment.id === id)

            if (index >= 0) {
                runInAction(() => {
                    this.segments.splice(index, 1)
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to delete the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async deleteXandr(id: number) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const index = this.segments.findIndex((x) => x.xandr && x.xandr.id === id)
            await this.adminService.deleteXandrSegment(id)

            if (index >= 0) {
                runInAction(() => {
                    this.segments[index].xandr = null
                })
            }
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to delete the segment'
            })
            runInAction(() => {
                this.error = error
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getUserCompany(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await companyService.getCompanyAdmin(id)
            runInAction(() => {
                this.adminCompany = res
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get the company'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }
}

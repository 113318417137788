export enum MimeType {
    TextPlain = 'text/plain',
    TextCSV = 'text/csv',
    ApplicationZIP = 'application/zip',
    ApplicationPDF = 'application/pdf',
    ApplicationJSON = 'application/json',
    ApplicationOctetStream = 'application/octet-stream',
    ApplicationXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
export function downloadFile(
    data: BlobPart,
    /**
     * Include the extension in the filename, e.g. 'file.txt'
     */
    filename: string,
    mimeType: MimeType = MimeType.TextCSV
) {
    const blob = new Blob([data], { type: mimeType })
    const blobURL = window.URL.createObjectURL(blob)

    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
}
